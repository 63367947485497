import React from "react";
import "./GoogleReview.scss";

const GoogleReviews = ({ src }) => {
  return (
    <div className="google-reviews">
      <iframe
        title="Google Reviews"
        src={src}
        width="100%"
        height="300"
        frameBorder="0"
        scrolling="yes"
      />
    </div>
  );
};


export default GoogleReviews;
