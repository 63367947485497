import React from "react";
import soreMuscles from "../../assets/images/soreMuscles.png";

export default function DisplayImage() {
  return (
    <img 
    src={soreMuscles} 
    alt="Pain in Muscles" 
    style={{ width: "auto", height: "auto", maxWidth: "100%", maxHeight: "100%", display: "block", margin: "0 auto" }}
    />
  );
}
