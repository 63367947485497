import React, {useContext} from "react";
import "./Talks.scss";
import TalkCard from "../../components/talkCard/TalkCard";
import {talkSection} from "../../portfolio";
import {Fade} from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import YoutubeLink from "../blogs/YoutubeLink";
import GoogleReview from "../blogs/GoogleReview";

export default function Talks() {
  const {isDark} = useContext(StyleContext);
  if (!talkSection.display) {
    return null;
  }
  return (
    <Fade bottom duration={1000} distance="20px">
      <div className="main" id="talks">
        {/* <div className="talk-header"> */}
          <h1 className="talk-header-title">{talkSection.title}</h1>
          <p
            className={
              isDark
                ? "dark-mode talk-header-subtitle"
                : "subTitle talk-header-subtitle"
            }
          >
            {talkSection.subtitle}
          </p>
          <YoutubeLink />
          <GoogleReview src={"https://widgets.sociablekit.com/google-reviews/iframe/143275"} />
          <GoogleReview src={"https://widgets.sociablekit.com/google-reviews/iframe/148378"}/>
        </div>
    </Fade>
  );
}
