import React from "react";
import drOmarKhan from "../../assets/images/drOmarKhanReplacement copy.png";
import test from "../../assets/images/replacement-test-drOk.jpg";
import anotherTest from "../../assets/images/2nd-ok-test.jpg";
import "./DrOmarKhanPic.scss"; 

export default function DisplayImage() {
  return (
    <img 
    src={drOmarKhan} 
    alt="Dr Omar Khan" 
    style={{ width: "auto", height: "auto", maxWidth: "100%", maxHeight: "100%", display: "block", margin: "0 auto" }}
    />
    // <div className="DrKhanPicContainer">
    //   <img
    //     className="DrKhanPic"
    //     src={drOmarKhan}
    //     alt="Example Image"
    //   />
    // </div>
  );
}
