import React from 'react';
import './YoutubeLink.scss';
import OmarKhanVidThumbnail from '../../assets/images/drOmarKhanVidThumbnail.png';

const YoutubeLink = ({ videoID }) => {
  return (
    <div className="YoutubeLinkContainer">
      <a
        href={`https://www.youtube.com/watch?v=gE8OQYFk0tw&ab_channel=OutcomeHealth/`}
        target="_blank"
        rel="noopener noreferrer"
        className="YoutubeLink"
      >
        <div className="YoutubeLinkThumbnail" style={{ '--thumbnail-url': `url(${OmarKhanVidThumbnail})` }} />
      </a>
    </div>
  );
};

export default YoutubeLink;

// import React from 'react';
// import './YoutubeLink.scss';
// import OmarKhanVidThumbnail from '../../assets/images/drOmarKhanVidThumbnail.png';

// const YoutubeLinkContainer = ({ videoID }) => {
//   return (
//     <div className="YoutubeLink
//   ">
//       <a href={`https://www.youtube.com/watch?v=gE8OQYFk0tw&ab_channel=OutcomeHealth/`}>
//         <img src={OmarKhanVidThumbnail} alt="YouTube Video" />
//       </a>
//     </div>
//   );
// };

// export default YoutubeLinkContainer;
