/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 1500 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Dr. Omar Khan",
  title: "Dr. Omar Khan",
  subTitle: emoji(
    "Dr. Omar T. Khan, FACR, is a highly skilled board certified rheumatologist who joined the Arthritis and Rheumatology Center in 2019. "
  ),
  resumeLink:
    "https://www.arcenterpc.com/contact-us.php", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  // github: "https://github.com/saadpasta",
  
  // gmail: "saadpasta70@gmail.com",
  // gitlab: "https://gitlab.com/saadpasta",
  // facebook: "https://www.facebook.com/saad.pasta7",
  linkedin: "https://www.linkedin.com/in/dromarkhan/",
  // medium: "https://medium.com/@saadpasta",
  // stackoverflow: "https://stackoverflow.com/users/10422806/saad-pasta",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "About Dr. Khan",
  subTitle: "Dr. Khan, located in North ATL Metro area, provides state-of-the-art rheumatologic care.",
  skills: [
    emoji(
      "He has been published in various journals and is a nationally recognized speaker on several rheumatologic conditions, such as rheumatoid arthritis, psoriatic arthritis, gout, and osteoporosis, among others."
    ),
    emoji(
      "Dr. Khan is dedicated to patient care and achieving positive outcomes by utilizing the latest treatments, including on-site IV infusions. Additionally, he offers on-site labs and imaging facilities. His approach is centered around patient care."
    ),
    emoji(
      "His medical interests include rheumatoid arthritis, psoriatic arthritis, lupus, Sjogren's syndrome, inflammatory myopathy, gout, sarcoid, and ankylosing spondylitis to name a few."
    ),
    emoji(
      "He is a member of the American College of Rheumatology, American Medical Association, Georgia Society of Rheumatology, American College of Physicians, and Doctors Without Borders."
    ),
    emoji(
      "Outside of medicine, he enjoys spending time with his family, traveling, and staying physically fit."
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "board certified",
      fontAwesomeClassname: "fa-solid fa-notes-medical"
    },
    
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    
    {
      schoolName: "New York Institute of Technology",
      logo: require("./assets/images/NYITlogo.png"),
      subHeader: "Bachelor of Science ",
      duration: "",
      desc: "Recieved Magna Cum Laude distinction",
      descBullets: []
    },
    {
      schoolName: "New York College of Osteopathic Medicine",
      logo: require("./assets/images/newYorkCollegeLogo.png"),
      subHeader: "Doctor of Osteopathic Medicine",
      duration: "",
      desc: "A member of the integrated accelerated program",
      descBullets: []
    },
    {
      schoolName: "Drexel University College of Medicine",
      logo: require("./assets/images/drexelLogo.png"),
      subHeader: "Residency in Internal Medicine",
      duration: "",
      desc: "Completed the residency in internal medicine",
      descBullets: []
    },
    {
      schoolName: "Ochsner Clinic",
      logo: require("./assets/images/ochsnerLogo.png"),
      subHeader: "Fellowship in Rheumatology",
      duration: "",
      desc: "Completed the residency in internal medicine",
      descBullets: []
    }
    // {
    //   role: "Fellowship in Rheumatology",
    //   company: "Ochsner Clinic",
    //   companylogo: require("./assets/images/ochsnerLogo.png"),
    //   date: "",
    //   desc: ""
    // }
    // {
    //   schoolName: "Ochsner Medical Center",
    //   logo: require("./assets/images/ochsnerLogo.png"),
    //   subHeader: "Fellowship in Rheumatology",
    //   duration: "2012 - 2014",
    //   desc: "Earned the Ochsner Clinic Foundation research fellow award.",
    //   descBullets: []
    // }

  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: false, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend",
      progressPercentage: "70%"
    },
    {
      Stack: "Programming",
      progressPercentage: "60%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: false, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Rheumatologist",
      company: "A&R Center PC",
      companylogo: require("./assets/images/arCenterLogo.jpeg"),
      date: "",
      desc: ""
    },
    {
      role: "Fellowship in Rheumatology",
      company: "Ochsner Clinic",
      companylogo: require("./assets/images/ochsnerLogo.png"),
      date: "",
      desc: ""
    },
    {
      role: "Physican",
      company: "Kaiser Permanente",
      companylogo: require("./assets/images/kaiser.jpeg"),
      date: "",
      desc: ""
    },
    
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Big Projects",
  subtitle: "SOME STARTUPS AND COMPANIES THAT I HELPED TO CREATE THEIR TECH",
  projects: [
    {
      image: require("./assets/images/saayaHealthLogo.webp"),
      projectName: "Saayahealth",
      projectDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      footerLink: [
        {
          name: "Visit Website",
          url: "http://saayahealth.com/"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/nextuLogo.webp"),
      projectName: "Nextu",
      projectDesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      footerLink: [
        {
          name: "Visit Website",
          url: "http://nextu.se/"
        }
      ]
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Google Code-In Finalist",
      subtitle:
        "First Pakistani to be selected as Google Code-in Finalist from 4000 students from 77 different countries.",
      image: require("./assets/images/codeInLogo.webp"),
      imageAlt: "Google Code-In Logo",
      footerLink: [
        {
          name: "Certification",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dYkVvNjdNWjNybWJrbndFSHpNY2NFV1p4YmU0/view?usp=sharing"
        },
        {
          name: "Award Letter",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dekxBTW5hQkg2WXUyR3QzQmR0VERiLXlGRVdF/view?usp=sharing"
        },
        {
          name: "Google Code-in Blog",
          url: "https://opensource.googleblog.com/2019/01/google-code-in-2018-winners.html"
        }
      ]
    },
    {
      title: "Google Assistant Action",
      subtitle:
        "Developed a Google Assistant Action JavaScript Guru that is available on 2 Billion devices world wide.",
      image: require("./assets/images/googleAssistantLogo.webp"),
      imageAlt: "Google Assistant Action Logo",
      footerLink: [
        {
          name: "View Google Assistant Action",
          url: "https://assistant.google.com/services/a/uid/000000100ee688ee?hl=en"
        }
      ]
    },

    {
      title: "PWA Web App Developer",
      subtitle: "Completed Certifcation from SMIT for PWA Web App Development",
      image: require("./assets/images/pwaLogo.webp"),
      imageAlt: "PWA Logo",
      footerLink: [
        {name: "Certification", url: ""},
        {
          name: "Final Project",
          url: "https://pakistan-olx-1.firebaseapp.com/"
        }
      ]
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Blogs Section

// const blogSection = {
//   title: "Links and Appointment Scheduling",
//   subtitle:
//     "",
//   displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
//   blogs: [
//     {
//       url: "https://www.arcenterpc.com/contact-us.php",
//       title: "Schedule an appointment",
//       description:
//         "Arthritis & Rheumatology Center, PC: Contact information in all of our locations. See the Cumming section details to book an appointment with Dr. Omar Khan."
//     },
//     {
//       url: "https://www.youtube.com/watch?v=gE8OQYFk0tw&source_ve_path=MjM4NTE&feature=emb_title&ab_channel=OutcomeHealth",
//       title: "Dr. Omar Khan video",
//       description:
//         "Dr. Omar Khan, Rheumatologist, and the Outcome Health Experience"
//     },
//   ],
//   display: true // Set false to hide this section, defaults to true
// };
const blogSection = {
  title: "Publications",
  subtitle:
    "",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "https://journals.aai.org/jimmunol/article-split/192/1_Supplement/184.13/53547/Circulating-T-helper-cells-with-T-follicular",
      title: "Circulating T helper cells",
      description:
        "Circulating T helper cells with T follicular helper cell phenotype renders the autoantibody generation in patients with systemic lupus erythematosus"
    },
    {
      url: "https://www.ochsnerjournal.org/content/14/3/343.abstract",
      title: "Fibromyalgia",
      description:
        "Fibromyalgia: Can Online Cognitive Behavioral Therapy Help?"
    },
    {
      url: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3603176/",
      title: "Follicular Helper T Cells",
      description:
        "Follicular Helper T Cells: New Insights Into Mechanisms of Autoimmune Diseases"
    },
    {
      url: "https://journals.lww.com/jclinrheum/Abstract/2012/10000/Occurrence_of_Rheumatoid_Arthritis_Requiring_Oral.8.aspx",
      title: "Modifying Antirheumatic Drug Therapy",
      description:
        "Occurrence of Rheumatoid Arthritis Requiring Oral and/or Biological Disease-Modifying Antirheumatic Drug Therapy Following a Diagnosis of Primary Sjögren Syndrome"
    },
    {
      url: "https://casereports.bmj.com/content/2014/bcr-2014-205737",
      title: "Squamous cell carcinoma",
      description:
        "Squamous cell carcinoma and eosinophilia in a long-term course of pansclerotic morphea"
    },
    {
      url: "https://pubmed.ncbi.nlm.nih.gov/23013848/",
      title: "Rheumatoid arthritis",
      description: 
        "Rheumatoid arthritis and primary Sjögren's syndrome: clinical, serological and immunogenetic aspects"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};



// Talks Sections

const talkSection = {
  title: "Youtube Video",
  subtitle: emoji(
    "Dr. Omar Khan, Rheumatologist, and the Outcome Health Experience"
  ),

  talks: [
    {
      // title: "Build Actions For Google Assistant",
      // subtitle: "Codelab at GDG DevFest Karachi 2019",
      // slides_url: "https://bit.ly/saadpasta-slides",
      // event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact"),
  subtitle:
    <a href="https://www.arcenterpc.com/contact-us.php">
    ARTHRITIS & RHEUMATOLOGY CENTER, PC
    </a>,
  number: "770-284-3150",
  email_address: "info@arcenterpc.com"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

const isHireable = false; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable
};
