// import React from 'react';
// import './Banner.css';

// const Banner = () => {
//   return (
//     <div className="banner">
//       Awarded top doctor in Rheumatology 2023
//     </div>
//   );
// };

// export default Banner;
import React from 'react';
import './Banner.css';

const Banner = () => {
  const websiteUrl = 'https://www.atlantamagazine.com/top-doctors/rheumatology/'; // Replace with your desired website URL

  return (
    <a href={websiteUrl} className="banner-link">
      <div className="banner">
        Awarded top doctor in Rheumatology 2023
      </div>
    </a>
  );
};

export default Banner;
